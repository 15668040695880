import './App.scss';
import {
	AuthenticationDetails,
	CognitoUser,
	CognitoUserPool,
} from 'amazon-cognito-identity-js';
import {
	Dialog,
	ErrorLog,
	FileManager,
	Login,
	PasswordInitialise,
	PasswordReset,
} from './components';
import { AwsRum } from 'aws-rum-web';
import {
	handleResponse,
} from './utility';
import jwt_decode from "jwt-decode"; // eslint-disable-line
import React from 'react';

class App extends React.Component {
	constructor(props) {
		super(props);

		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const resetCode = urlParams.get('reset_code');

		this.state = ({
			dialogMessage: '',
			dialogShow: false,
			dialogType: 'clear',
			errors: [],
			label: '',
			loggedIn: false,
			refreshErrorLog: false,
			resetCode: resetCode,
			showPasswordReset: resetCode !== null,
			showSpinner: false,
		});

		this.affirmDialog = this.affirmDialog.bind(this);
		this.clearDialog = this.clearDialog.bind(this);
		this.clearError = this.clearError.bind(this);
		this.clearLog = this.clearLog.bind(this);
		this.dialog = this.dialog.bind(this);
		this.handleForgotPassword = this.handleForgotPassword.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
		this.handleShowSpinner = this.handleShowSpinner.bind(this);
		this.logError = this.logError.bind(this);
	}

	affirmDialog = () => {
		const { dialogYes } = this.state;
		this.setState({
			dialogMessage: '',
			dialogShow: false,
		}, () => { if (dialogYes) dialogYes(); });
	};

	fetchSSOConfig = (SSOEndpoint, emailDomain, appName) => {
		return fetch(`${SSOEndpoint}?domain=${emailDomain.toLocaleLowerCase()}&product=${appName}`)
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.catch(error => {
				console.log('Error fetching SSO config:', error);
				return null;
			});
	};

	clearDialog = () => {
		const {
			dialogNo,
		} = this.state;
		this.setState({
			dialogMessage: '',
			dialogShow: false,
		}, () => {
			if (dialogNo) dialogNo();
		});
	};

	clearError = (index) => {
		// console.log("clearError", index);
		const { errors } = this.state;
		errors.splice(index, 1);
		this.setState({
			errors: errors,
		});
	};

	clearLog = () => {
		this.setState({
			errors: [],
		});
	};

	componentDidMount = () => {
		// Read the config
		const headers = new Headers();
		headers.append("Content-Type", "application/json");

		const requestOptions = {
			headers: headers,
			method: 'GET',
			redirect: 'follow',
		};

		let adminPanel = false;
		if (document.location.origin.slice(0, 14) === 'https://admin.') adminPanel = true;

		// show error messages from querystring
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const error = urlParams.get('error');
		if (error) {
			this.setState({
				loginError: error,
			});
			// Remove error from querystring
			const url = new URL(window.location.href);
			url.searchParams.delete('error');
			window.history.replaceState({}, document.title, url);
		}

		fetch(`${window.location.origin}/config.json`, requestOptions)
			.then(handleResponse)
			.then(res => {
				const {
					appName = 'StarDrop',
					environment,
					RUM_APPLICATION_ID,
					RUM_APPLICATION_REGION,
					RUM_APPLICATION_VERSION,
					RUMendpoint,
					RUMguestRoleArn,
					RUMidentityPoolId,
					RUMrecordResourceUrl,
					RUMsessionSampleRate,
					RUMtelemetries,
					version = '',
				} = res;

				if (RUMendpoint && RUMendpoint !== '') {
					try {
						const config = {
							allowCookies: true,
							enableXRay: false,
							endpoint: RUMendpoint,
							guestRoleArn: RUMguestRoleArn,
							identityPoolId: RUMidentityPoolId,
							recordResourceUrl: RUMrecordResourceUrl,
							sessionSampleRate: RUMsessionSampleRate,
							telemetries: RUMtelemetries,
						};

						const awsRum = new AwsRum( // eslint-disable-line
							RUM_APPLICATION_ID,
							RUM_APPLICATION_VERSION,
							RUM_APPLICATION_REGION,
							config
						);
					} catch (error) {
						// Ignore errors thrown during CloudWatch RUM web client initialization
						console.log("RUM integration error", error); // eslint-disable-line
					}
				}

				let strEnvironment = '';
				if (appName === 'StarDrop') {
					if (adminPanel) {
						if (environment.length) strEnvironment = ` [${environment}]`;
						document.title = `StarDrop File Manager${strEnvironment}`;
						document.getElementsByTagName('meta')["description"].content = document.title;
					}
				} else {
					// Semeta
					document.title = `Semeta ${version}`;
					document.getElementsByTagName('meta')["description"].content = document.title;

				}
				let link = document.querySelector("link[rel~='icon']");
				if (!link) {
					link = document.createElement('link');
					link.rel = 'icon';
					document.head.appendChild(link);
				}
				if (appName === 'StarDrop') {
					link.href = 'favicon.png';
				} else {
					link.href = 'semeta.svg';
				}

				const label = environment === 'beta' ? environment : '';

				// Fetch id_token from querystring
				const jwtToken = urlParams.get('id_token');

				// Check if user will have file/directory structure API access
				if (adminPanel && jwtToken) {
					// Remove id_token from querystring
					const url = new URL(window.location.href);
					url.searchParams.delete('id_token');
					window.history.replaceState({}, document.title, url);

					// Check if user will have file/directory structure API access
					const decode = jwt_decode(jwtToken);  // eslint-disable-line
					this.setState({
						email: decode['email'],
						jwtToken: jwtToken,
						loggedIn: true,
						loginError: '',
					});
				}

				this.setState({
					adminPanel: adminPanel,
					label: label,
					...res
				}, this.validate);
			})
			.catch(error => {
				const action = `Retrieving SaaS configuration`;
				this.logError(action, error);
			});
	};

	dialog = (message, theme, type = 'clear', yes, no) => {
		this.setState({
			dialogMessage: message,
			dialogNo: no,
			dialogShow: true,
			dialogTheme: theme,
			dialogType: type,
			dialogYes: yes,
		});
	};

	handleForgotPassword = (email) => {
		const {
			cognito,
		} = this.state;

		const {
			userPoolClientId,
			userPoolId,
		} = cognito;
		// console.log("handleForgotPassword");

		this.setState({
			showSpinner: true,
		});

		const poolData = new CognitoUserPool({
			ClientId: userPoolClientId,
			UserPoolId: userPoolId,
		});

		const user = new CognitoUser({
			Pool: poolData,
			Username: email,
		});

		const _this = this;
		user.forgotPassword({
			onFailure: (err) => {
				_this.handleShowSpinner(false);
				_this.dialog(err);
			},
			onSuccess: () => {
				_this.handleShowSpinner(false);
				_this.dialog(`An email has been sent to your registered email address. Please check your email.`);
			},
		});
	};

	handleLogin = (e, email = '', password = '') => {
		const {
			adminPanel = false,
			cognito,
			SSOEndpoint,
			appName
		} = this.state;

		const {
			userPoolClientId,
			userPoolId,
		} = cognito;
		// console.log("handleLogin");

		// Doing it (getting email and password) this way as using handleChange does not handle the auto form fill case as
		// there is no change event to initialise the fields.
		if (email === '' && password === '') {
			// Set from form data
			({
				email,
				password,
			} = e.target);
			email = email.value;
			if (password) password = password.value;
		} // else passed from handleSetPassword

		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		this.setState({
			showSpinner: true,
		});

		const emailDomain = email.split('@').pop();
		this.fetchSSOConfig(SSOEndpoint, emailDomain, appName).then(identityProvider => {
			if (identityProvider) {
				const { SSOEndpoint, AdminSSOEndpoint, Product } = identityProvider;
				if (adminPanel && AdminSSOEndpoint) {
					window.location.href = `${AdminSSOEndpoint}?email=${email}&product=${Product}`;
					return;
				} else if (!adminPanel && SSOEndpoint) {
					window.location.href = `${SSOEndpoint}?email=${email}&product=${Product}`;
					return;
				}
			} else {
				const poolData = new CognitoUserPool({
					ClientId: userPoolClientId,
					UserPoolId: userPoolId,
				});

				const user = new CognitoUser({
					Pool: poolData,
					Username: email,
				});

				const authDetails = new AuthenticationDetails({
					Password: password,
					Username: email,
				});

				// console.log("authDetails", authDetails);
				user.authenticateUser(authDetails, {
					newPasswordRequired: (userAttributes, requiredAttributes) => {
						delete userAttributes.email_verified;
						// console.log('new password required', userAttributes, requiredAttributes);

						// Only send attributes that are in the requiredAttributes list
						const attributesForChallenge = {};
						for (const attr of requiredAttributes) {
							attributesForChallenge[attr] = userAttributes[attr];
						}

						// Render a new form prompting user for new password
						// Type twice
						// Call this with new password when hit submit user.completeNewPasswordChallenge
						this.setState({
							attributesForChallenge: attributesForChallenge,
							authDetails: authDetails,
							email: email,
							loggedIn: false,
							poolData: poolData,
							showPasswordInitialise: true,
							showSpinner: false,
							user: user,
						});
					},
					onFailure: (err) => {
						// console.log('login failure', err);
						this.setState({
							email: email,
							loggedIn: false,
							loginError: 'Incorrect username or password',
							showSpinner: false,
						});

					},
					onSuccess: (result) => {
						// console.log(20,"onSuccess");
						// console.timeLog('AWStiming');
						this.setState({
							loggedIn: true,
							loginError: '',
						});

						const {
							idToken
						} = result;

						const {
							APIEndpoint,
						} = this.state;

						const { jwtToken } = idToken;

						// Check if user will have file/directory structure API access
						if (adminPanel) {
							const decode = jwt_decode(jwtToken);  // eslint-disable-line
							const groups = decode['cognito:groups'];
							if (!groups.includes('tenant-admins')) {
								this.setState({
									loggedIn: false,
									loginError: `No permission to access admin panel.`,
									showSpinner: false,
								});
								return;
							}
						}

						this.setState({
							APIEndpoint: APIEndpoint,
							email: email,
							jwtToken: jwtToken,
							loggedIn: true,
							loginError: '',
						});

						const headers = new Headers();
						headers.append("Authorization", jwtToken);
						// headers.forEach((h) => console.log("header: ", h));

						const requestOptions = {
							headers: headers,
							method: 'POST',
							// DO NOT DO THIS AS HALF THE HEADERS WILL NOT GO THROUGH! mode: 'no-cors',
							// https://stackoverflow.com/questions/45591594/fetch-does-not-send-headers
							redirect: 'follow',
						};

						if (adminPanel) {
							return; // No redirect!
						}

						fetch(`${APIEndpoint}/auth`, requestOptions)
							.then(handleResponse)
							.then(res => {
								const { Message } = res;
								// Redirect to StarDrop in the cloud
								window.location.href = Message;
							})
							.catch(error => {
								const action = `Redirecting to SaaS`;
								console.log(`${action}`, error); // eslint-disable-line
								this.setState({
									showSpinner: false,
								});
							});
					},
				});
			}
		});
	};

	handleLogout = () => {
		this.resetStateOnLogout();
	};

	handlePasswordInitialise = (e) => {
		// console.log("handlePasswordInitialise");
		const {
			attributesForChallenge,
			email,
			user,
		} = this.state;

		// Doing it (getting email and password) this way as using handleChange does not handle the auto form fill case as
		// there is no change event to initialise the fields.
		const {
			password,
		} = e.target;

		const { value: newPassword } = password;

		e.preventDefault();
		e.stopPropagation();

		// Call this with new password when hit submit
		user.completeNewPasswordChallenge(newPassword, attributesForChallenge, {
			onFailure: (err) => {
				// console.log('login failure', err);
				this.setState({
					email: email,
					loggedIn: false,
					passwordError: err.message,
					showSpinner: false,
				});
			},
			onSuccess: (result) => {
				// console.log(20, "onSuccess", result);
				const {
					idToken
				} = result;

				const {
					APIEndpoint,
				} = this.state;

				const { jwtToken } = idToken;

				this.setState({
					APIEndpoint: APIEndpoint,
					email: email,
					jwtToken: jwtToken,
					loggedIn: true,
					loginError: '',
				}, (e) => this.handleLogin(e, email, newPassword));
			},
		});
	};

	handlePasswordReset = (e) => {
		// console.log("handlePasswordReset");
		const {
			cognito,
			resetCode,
		} = this.state;

		const {
			userPoolClientId,
			userPoolId,
		} = cognito;

		const {
			email,
			password,
		} = e.target;

		e.preventDefault();
		e.stopPropagation();

		const poolData = new CognitoUserPool({
			ClientId: userPoolClientId,
			UserPoolId: userPoolId,
		});

		const user = new CognitoUser({
			Pool: poolData,
			Username: email.value,
		});

		const { value: newPassword } = password;

		const _this = this;
		user.confirmPassword(resetCode, newPassword, {
			onFailure(err) {
				_this.dialog(err.message);
			},
			onSuccess(result) {
				// console.log("result", result);

				_this.setState({
					loggedIn: false,
					loginError: '',
				});

				_this.dialog("Password set, you may now login", undefined, 'clear', () => { }, () => {
					// Go to login
					[window.location] = window.location.href.split('?');
				});

			},
		});

	};

	handleShowSpinner = (show) => {
		this.setState({ showSpinner: show });
	};

	logError = (action, ...params) => {
		// Accepts
		// (action, statusCode, statusText, message) or
		// (action, error)
		const {
			errors,
			refreshErrorLog,
		} = this.state;
		if (params.length === 1) {
			// Is error object
			const [error] = params;
			const {
				status = '',
				statusText = '',
			} = error;
			let message = '';
			if (error.message) message += error.message;
			if (error.detail) message += error.detail;

			errors.push({
				action: action,
				message: message,
				statusCode: status,
				statusText: statusText,
			});

		} else {
			const [statusCode = '', statusText = '', message = ''] = params;

			errors.push({
				action: action,
				message: message,
				statusCode: statusCode,
				statusText: statusText,
			});
		}

		this.setState({
			errors: errors,
			refreshErrorLog: !refreshErrorLog,
			showSpinner: false,
		});
	};

	render = () => {
		const {
			adminPanel = false, // Deduced from 1st 14 characters of URL
			animated = false,
			APIEndpoint,
			SSOEndpoint,
			appName, // from config.json
			cognito,
			dialogShow,
			dialogMessage,
			dialogType,
			email,
			errors,
			jwtToken,
			label = '',
			loggedIn = false,
			loginError,
			passwordError,
			refreshErrorLog,
			showPasswordReset = false,
			showPasswordInitialise = false,
			showSpinner = false,
			temporaryMessage = ''
		} = this.state;

		if (cognito) return (
			<div className={`app ${appName === 'StarDrop' ? 'stardrop' : ''} ${appName === 'Semeta' ? 'semeta' : ''} skin ${adminPanel ? 'file-manager' : ''}`}>
				<ErrorLog
					clearError={this.clearError}
					clearLog={this.clearLog}
					dialog={this.dialog}
					errors={errors}
					refreshErrorLog={refreshErrorLog}
				/>
				{dialogShow ? <Dialog
					handleAffirm={this.affirmDialog}
					handleClear={this.clearDialog}
					message={dialogMessage}
					type={dialogType}
				/> : null}
				{temporaryMessage !== '' ?
					<Dialog
						handleAffirm={() => {
							this.setState({ temporaryMessage: '' },
								this.clearDialog()
							);
						}}
						handleClear={() => {
							this.setState({ temporaryMessage: '' },
								this.clearDialog()
							);
						}}
						message={temporaryMessage}
						type={'clear'}
					/>
					:
					null
				}
				{showSpinner ? <div id='spinner'>
					{/* <div className={`ring ringone`}/>
					<div className={`ring ringtwo`}/>
					<div className={`ring ringthree`}/> */}
				</div> : null}

				{!loggedIn || !adminPanel ?
					<>
						{/* <p>ShowPasswordInitialise: {showPasswordInitialise ? 'true' : 'false'}</p>
						<p>ShowPasswordReset: {showPasswordReset ? 'true' : 'false'}</p> */}
						{showPasswordReset ?
							<PasswordReset
								cognito={cognito}
								email={email}
								handlePasswordReset={this.handlePasswordReset}
								label={`Set password`}
								logError={this.logError}
								passwordError={passwordError}
							/>
							:
							null
						}
						{showPasswordInitialise ?
							<PasswordInitialise
								cognito={cognito}
								email={email}
								handlePasswordInitialise={this.handlePasswordInitialise}
								label={`Set password`}
								logError={this.logError}
								passwordError={passwordError}
							/>
							:
							null
						}
						{!showPasswordInitialise && !showPasswordReset ?
							<Login
								adminPanel={adminPanel}
								appName={appName}
								animated={animated}
								cognito={cognito}
								dialog={this.dialog}
								handleForgotPassword={this.handleForgotPassword}
								handleLogin={this.handleLogin}
								label={label}
								logError={this.logError}
								loginError={loginError}
								SSOEndpoint={SSOEndpoint}
							/>
							:
							null
						}
					</>
					:
					<>
						{adminPanel ?
							<FileManager
								appName={appName}
								APIEndpoint={APIEndpoint}
								dialog={this.dialog}
								handleLogout={this.handleLogout}
								handleShowSpinner={this.handleShowSpinner}
								jwtToken={jwtToken}
								logError={this.logError}
							/>
							:
							null
						}
					</>
				}
			</div>
		);
	};

	resetStateOnLogout = () => {
		this.setState({

			dialogMessage: '',
			dialogShow: false,
			dialogType: 'clear',

			errors: [],

			jwtToken: undefined, // Expired anyway!

			loggedIn: false,

			showSpinner: false,
		});

	};
}

export default App;
